<template>
  <div id="plantillas_examen">
    <MenuBase
      :tipo="'plantillasexamen'"
      :headers="headers"
      :items="$store.state.tabla"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-clipboard-text-multiple'"
      :verTabla="true"
      :loading="!$store.state.tabla.length"
    />
    <PlantillaExamen :modo.sync="modoPlantillaExamen" :dialogPE.sync="dialogPlantillaExamen" :nplantilla.sync="campoOpcion"/>
  </div>
</template>

<script>
import Globales from '@/mixins/globales.vue'
import MenuBase from '@/components/menubase.vue'
import PlantillaExamen from '@/views/plantillaexamen.vue'
import ApiCon from '@/logic/apicon.js'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    PlantillaExamen
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Plantillas de Exámenes',
    dialog: false,
    tituloOpciones: 'Opciones plantilla: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoPlantillaExamen: 'NUEVA',
    dialogPlantillaExamen: false,
    labelActivado: '',
    colorActivado: ''
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre' },
        { text: 'TIPO', align: 'start', value: 'examen_tipo' },
        { text: 'MUESTRA', align: 'start', value: 'muestra' },
        { text: 'ESTADO', align: 'start', value: 'estado' },
        { text: 'NOTAS', align: 'start', value: 'notas' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVA PLANTILLA', color: 'primary', accion: 'nuevaPlantilla' }
      ]
    },
    clicFila (fila) {
      this.campoOpcion = fila.nombre
      this.$store.commit('cambiarID', fila.id)
      this.cambiarActivacion(fila.estado)
    },
    clicBoton (accion) {
      if (accion === 'nuevaPlantilla') {
        this.modoPlantillaExamen = 'NUEVA'
        this.dialogPlantillaExamen = true
      } else if (accion === 'editarPlantilla') {
        this.modoPlantillaExamen = 'EDITAR'
        this.dialogPlantillaExamen = true
      } else if (accion === 'activarPlantilla') {
        this.estadoExamen()
      }
    },
    estadoExamen () {
      if (this.colorActivado === 'success') {
        if (confirm('¿Está seguro que desea activar esta plantilla?')) {
          this.cambiarEstadoPlantilla('Activado')
        } else {}
      } else {
        if (confirm('¿Está seguro que desea desactivar esta plantilla?')) {
          this.cambiarEstadoPlantilla('Desactivado')
        } else {}
      }
    },
    async cambiarEstadoPlantilla (estado) {
      this.loading = true
      const ins = {
        id: this.$store.state.ids,
        estado: estado
      }
      const response = await ApiCon.post('examenplantillas/cambiarestado', ins)
      if (response.status === 200) {
        alert('El estado de la plantilla ha sido cambiada')
        this.cambiarActivacion(estado)
        this.cargarItems()
      } else { alert('error cambiarEstadoPlantilla') }
      this.loading = false
    },
    cambiarActivacion (estado) {
      if (estado === 'Activado') {
        this.labelActivado = 'DESACTIVAR PLANTILLA'
        this.colorActivado = 'error'
      } else {
        this.labelActivado = 'ACTIVAR PLANTILLA'
        this.colorActivado = 'success'
      }
      this.opciones = []
      this.opciones = [
        { id: 1, nombre: 'VER/EDITAR PLANTILLA', color: 'primary', accion: 'editarPlantilla' },
        { id: 2, nombre: this.labelActivado, color: this.colorActivado, accion: 'activarPlantilla' }
      ]
    },
    cargarItems () {
      this.cargarTabla('examenplantillas/todo', 'cambiarTabla', 'cargarTablaPlantillas')
    },
    cargarDatos () {
      if (this.compruebaLogin() === true) {
        if (this.returnPrilegio('plantillas') === true) {
          this.interfaz()
          this.cargarItems()
        } else { this.$router.push('/menu') }
      }
    }
  },
  created () {
    this.haySesion()
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
