<template>
  <div id="plantillaexamen">
    <DialogVentana :dialog.sync="dialogInput" :loading.sync="loading" :width="800">
      <v-card style="box-shadow: none;">
        <v-card-text>
          <p class="display-1 primary--text font-weight-regular mt-2"> {{modoInput}} {{titulo}}</p>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-text-field
                  label="Nombre Plantilla"
                  v-model="nombre"
                  style="margin-bottom: -5px;"
                  :rules="[requerido]"
                  counter
                  maxlength="45"
                  class="mb-2"
                  @change="existePlantilla"
                ></v-text-field>
                <v-textarea
                  v-model="notas"
                  auto-grow
                  filled
                  label="Notas"
                  rows="3"
                  :rules="[requerido]"
                  counter
                  maxlength="100"
                  class="mt-1 mb-3"
                ></v-textarea>
                <SelectCrud
                  :items="$store.state.tiposexamen"
                  store="cambiarTiposExamen"
                  metodo="cargarListaTiposExamen"
                  :model.sync="tipoexamen"
                  api_carga="examentipos/listado"
                  :dialog.sync="dialogTipoExamen"
                  label="Tipo Examen"
                  :modo.sync="modoTipoExamen"
                  nnuevo="NUEVO">
                  <TipoExamen :modo.sync="modoTipoExamen" :dialogTE.sync="dialogTipoExamen" :ntipoexamen.sync="tipoexamen" origen="select"/>
                </SelectCrud>
                <SelectCrud
                  :items="$store.state.examenes"
                  store="cambiarExamenes"
                  metodo="cargarListaExamenes"
                  :model.sync="examen"
                  api_carga="examenes/listaexamenesnoplantilla"
                  :dialog.sync="dialogExamen"
                  label="Examen"
                  :modo.sync="modoExamen"
                  nnuevo="NUEVO"
                  :filtrar.sync="filtrarExamenes"
                  api_filtro="examenes/listaexamenesnoplantillafiltro">
                  <Examen :modo.sync="modoExamen" :dialogE.sync="dialogExamen" :nexamen.sync="examen" :origen="origenExamen"/>
                </SelectCrud>
                <v-row justify="center" class="mb-3">
                  <v-btn color="primary" class="mr-1" @click="agregarExamen()">AGREGAR</v-btn>
                  <BotonCopiar
                    api_existe="examenes/existe"
                    api_copiar="examenes/copiar"
                    api_lista="examenes/listaexamenesnoplantilla"
                    api_listafiltro="examenes/listaexamenesnoplantillafiltro"
                    titulo="Copiar Examen"
                    :dialog.sync="dialogCopiarExamen"
                    :loading.sync="loading"
                    label="Nombre examen copia"
                    :nombre.sync="examen"
                    :copia.sync="copiaExamen"
                    :filtrar.sync="filtrarExamenes"
                    store="cambiarExamenes"
                    error="cargarListaExamenes"
                    modo="select"
                  />
                </v-row>
                <v-row justify="center" class="mb-3">
                  <v-checkbox label="Etiqueta Única" v-model="etiq_unica"></v-checkbox>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <ListaSimple
                  :titulo.sync="lexamenesTitulo"
                  :seleccionado.sync="examenSeleccionado"
                  :items.sync="examenes"
                  @clic-item="quitarExamen"
                  :icono.sync="iconoLista"
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-btn
                color="primary"
                class="mb-3"
                @click="guardar"
                :disabled="!valid"
              >
              GUARDAR
              </v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </DialogVentana>
  </div>
</template>

<script>
import SelectCrud from '@/components/selectcrud.vue'
import Examen from '@/views/examen.vue'
import ApiCon from '@/logic/apicon.js'
import Globales from '@/mixins/globales.vue'
import ListaSimple from '@/components/listasimple.vue'
import DialogVentana from '@/components/dialogventana.vue'
import BotonCopiar from '@/components/botoncopiar.vue'

export default {
  data: () => ({
    titulo: 'Plantilla Exámen',
    nombre: '',
    examen: '',
    notas: '',
    examenes: [],
    dialogCopiarExamen: false,
    copiaExamen: '',
    examenSeleccionado: null,
    backexamen: 'white',
    valid: false,
    requerido: v => !!v || 'Este campo es obligatorio',
    loading: false,
    tipoback: 'white',
    existeEx: false,
    muestra: '',
    filtrarExamenes: false,
    lexamenesTitulo: 'Exámenes de esta plantilla',
    iconoLista: 'mdi-test-tube',
    dcopiarTitulo: '',
    dialogExamen: false,
    modoExamen: 'NUEVO',
    origenExamen: 'select',
    tipoexamen: '',
    dialogTipoExamen: false,
    modoTipoExamen: 'NUEVO',
    etiq_unica: false
  }),
  mixins: [
    Globales
  ],
  components: {
    SelectCrud,
    Examen,
    ListaSimple,
    DialogVentana,
    BotonCopiar
  },
  props: ['modo', 'dialogPE'],
  methods: {
    reset () {
      this.nombre = ''
      this.notas = ''
      this.examen = ''
      this.examenes = []
      this.tipoexamen = ''
      this.muestra = ''
      this.filtrarExamenes = false
      this.etiq_unica = false
    },
    async existePlantilla () {
      const response = await ApiCon.get2('examenplantillas/existe', this.nombre, this.$session.get('usuario'))
      if (response.status === 200) {
        if (response.data === true) {
          this.nombre = ''
          alert('Una plantilla con este nombre ya se encuentra registrada.')
        }
      } else { alert('error existePlantilla') }
    },
    async cargarPlantilla () {
      this.loading = true
      const response = await ApiCon.get1('examenplantillas/uno', this.$store.state.ids)
      if (response.status === 200) {
        this.nombre = response.data.nombre
        this.notas = response.data.notas
        this.tipoexamen = response.data.examen_tipo
        this.muestra = response.data.muestra
        if (response.data.etiq_unica === 'si') {
          this.etiq_unica = true
        } else {
          this.etiq_unica = false
        }
        for (var i = 0; i < response.data.examenes.length; i++) {
          this.examenes.push({ nombre: response.data.examenes[i] })
        }
        this.examen = response.data.examenes[0]
        // this.filtrarExamenes = true filtro para exámenes según primer seleccionado desactivado
      } else { alert('Error en cargarPlantilla...') }
      this.loading = false
    },
    postMod () {
      this.cargarTabla('examenplantillas/todo', 'cambiarTabla', 'cargarTablaPlantillas')
      this.$refs.form.reset()
      this.reset()
      this.dialogInput = false
    },
    async guardar () {
      if (this.valid === true & this.examenes.length > 0) {
        this.loading = true
        var ins = {}
        var examenesListos = []
        for (var i = 0; i < this.examenes.length; i++) {
          examenesListos.push(this.examenes[i].nombre)
        }
        var etiq = 'no'
        if (this.etiq_unica === true) {
          etiq = 'si'
        }
        if (this.modo === 'NUEVA') {
          ins = {
            nombre: this.nombre,
            notas: this.notas,
            examenes: examenesListos,
            examen_tipo: this.tipoexamen,
            usuario: this.$session.get('usuario'),
            etiq_unica: etiq
          }
          const response = await ApiCon.post('examenplantillas/insertar', ins)
          if (response.status === 200) {
            alert('La plantilla ha sido registrada.')
            this.postMod()
          } else { alert('Ha ocurrido un error con el guardado de datos') }
        } else if (this.modo === 'EDITAR') {
          ins = {
            id: this.$store.state.ids,
            nombre: this.nombre,
            notas: this.notas,
            examenes: examenesListos,
            examen_tipo: this.tipoexamen,
            usuario: this.$session.get('usuario'),
            etiq_unica: etiq
          }
          const response = await ApiCon.post('examenplantillas/update', ins)
          if (response.status === 200) {
            alert('La plantilla ha sido actualizada.')
            this.postMod()
          } else { alert('Ha ocurrido un error con la edición de datos') }
        }
        this.loading = false
      } else {
        alert('Debe seleccionar al menos 2 exámenes para completar la plantilla.')
      }
    },
    verDialogCopiarExamen () {
      if (this.examen !== '' & this.examen !== null) {
        this.dialogCopiarExamen = true
      } else {
        alert('Debe seleccionar un exámen primero.')
      }
    },
    agregarExamen () {
      if (this.examen !== '') {
        if (this.compruebaExamenAgregado() === false) {
          this.examenes.push({ nombre: this.examen })
          if (this.examenes.length === 1) {
            this.filtrarExamenes = false
          } else {
            this.filtrarExamenes = false
          }
        }
      }
    },
    compruebaExamenAgregado () {
      var existe = 0
      for (var i = 0; i < this.examenes.length; i++) {
        if (this.examenes[i].nombre === this.examen) {
          existe++
          i = this.examenes.length + 1
        }
      }
      if (existe > 0) {
        alert('El examen que intenta agregar ya está seleccionado para esta plantilla.')
        this.examen = ''
        return true
      }
      return false
    },
    quitarExamen (estado, nombre, id, index) {
      this.examenes.splice(index, 1)
      if (this.examenes.length === 0) {
        this.filtrarExamenes = false
      }
    }
  },
  created () {
    //
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogPE
      },
      set: function (newValue) {
        this.$emit('update:dialogPE', newValue)
      }
    },
    modoInput: {
      get: function () {
        return this.modo
      },
      set: function (newValue) {
        this.$emit('update:modo', newValue)
      }
    }
  },
  watch: {
    dialogPE () {
      if (this.dialogPE === false) {
        this.$refs.form.reset()
        this.reset()
      } else {
        if (this.modo === 'EDITAR') {
          this.cargarPlantilla()
        }
      }
    },
    dialogCopiarExamen () {
      if (this.dialogCopiarExamen === true) {
        this.dcopiarTitulo = 'Copiando exámen: ' + this.examen
      }
    },
    filtrarExamenes () {
      if (this.filtrarExamenes === true) {
        this.origenExamen = 'selectfiltro'
      } else {
        this.origenExamen = 'select'
      }
    }
  }
}
</script>
